import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { CustomTheme } from "@theme"
import useCssUtil from "@hooks/use-css-util"
import classNames from "classnames"
import React from "react"
import { Link } from "gatsby-plugin-intl"
import clsx from "clsx"

const useCss = makeStyles((theme: CustomTheme) => ({
  bioCard: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: 10,
    boxShadow: "0 15px 30px 0 rgba(1,1,13, 0.1)",
  },
  imgContainer: {
    height: 275,
    backgroundColor: "#fbfbfb",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    overflow: "hidden",
  },
  bioCardData: {
    padding: "25px 30px",
    [theme.breakpoints.up("sm")]: {
      padding: "30px 40px",
    },
  },
  title: {
    color: theme.custom.primaryTextColor,
    fontWeight: 700,
    fontSize: `.9rem`,
  },
  link: {
    color: theme.custom.primaryTextColor,
    textDecoration: "none",
    transition: "color 200ms",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  image: {
    objectFit: `cover`,
    height: `100%`,
    width: `100%`,
  },
  shortbio: {
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(26),
    },
  },
}))

export interface BioCardProps {
  name: string
  title: string
  image: string
  shortBio: string
  detailPageUrl: string
}

export default function BioCard({
  name,
  title,
  image,
  shortBio,
  detailPageUrl,
}: BioCardProps) {
  const classes = useCss()
  const cssUtil = useCssUtil()

  return (
    <article className={classes.bioCard}>
      <div className={classes.imgContainer}>
        {!!image && <img className={classes.image} src={image} alt="" />}
      </div>
      <div className={classes.bioCardData}>
        <Typography variant="h5" component="h3" className={cssUtil.mb5}>
          {name}
        </Typography>
        <Typography
          variant="body1"
          className={classNames(cssUtil.mb20, classes.title)}
        >
          {title}
        </Typography>
        <Typography
          className={clsx(cssUtil.mb12, classes.shortbio)}
          variant="body1"
          color="textSecondary"
        >
          {shortBio}
        </Typography>
        <Typography variant="body1">
          <Link to={detailPageUrl} className={classes.link}>
            {" "}
            Read More
          </Link>
        </Typography>
      </div>
    </article>
  )
}
