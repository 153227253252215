import BioCard from "@components/BioCard"
import Contact from "@components/contact"
import ContentHero from "@components/ContentHero"
import CTABlock from "@components/CTABlock"
import Seo from "@components/Seo"
import useTeamPage from "@hooks/use-team-page"
import { Container, Grid } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import * as routes from "@routes"
import { CustomTheme } from "@theme"
import React from "react"
import { option } from "tiinvo"
import { navigate } from "gatsby"

const createIdSection = (route: string) => <div id={route.replace("/#", "")} />

const useCss = makeStyles((theme: CustomTheme) => ({
  gridContainer: {
    paddingTop: theme.custom.sectionSpacingFluid,
    paddingBottom: theme.custom.sectionSpacingFluid,
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.custom.sectionSpacingLg,
      paddingBottom: theme.custom.sectionSpacingLg,
    },
  },
}))

export default function TeamPage() {
  const classes = useCss()
  const pagedata = useTeamPage()

  if (option.isNone(pagedata)) {
    navigate(routes.error404)
    return null
  }

  const { content } = option.unwrap(pagedata)!

  return (
    <>
      <Seo description={content.seo.description} title={content.seo.title} />
      <ContentHero
        title={content.hero.title}
        description={content.hero.subtitle}
      />
      <Container maxWidth="lg" className={classes.gridContainer}>
        <Grid container spacing={4}>
          {content.actors.map((actor, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <BioCard
                image={actor.image}
                name={actor.name}
                title={actor.role}
                shortBio={actor.shortbio}
                detailPageUrl={actor.detailPageUrl}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <CTABlock />
      {createIdSection(routes.contact)}
      <Contact />
    </>
  )
}
